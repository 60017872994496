@import '../imports/mixins';
@import '~ox-ui-css/styles/helpers';

// Elements overwritten in navbar are due to the $nav-padding on the anchor element.

.interior-layout {
  @extend %flex--full-column;
  overflow: auto;
  width: 100%;

  .interior-layout__internal-link {
    &:hover,
    &:focus {
      color: $ox-link-hover;
    }
  }

  .product-header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background: $color-white;
    box-sizing: content-box;
    padding: 0 $nav-padding-extra;

    .product-header__back-button {
      @include transition;
      @include bold-link;

      display: flex;
      padding: $grid-gutter-width;
      margin-right: -$grid-gutter-width;
      margin-left: 2px;

      &:hover,
      &:focus {
        @include bold-link-hover;
      }

      &.tabbable:focus {
        outline-offset: 0;
      }

      .product-header__back-button__text {
        @include text-overflow;
        padding-left: $padding / 2;
        letter-spacing: 0.05em;

        @media (max-width: $breakpoint-tablet * 1.2) {
          display: none;
        }
      }
    }

    .navbar {
      height: inherit;

      .navbar__link {
        color: $ox-heading-color;
        font-size: $ox-input-font-size;
        font-family: $ox-base-font;

        // Show grey line on hover or focus
        &:focus,
        &:hover {
          &::before {
            background: $color-gray-dark;
          }
        }

        // Show red line when selected
        &.selected {
          &::before {
            background: $intralox-red;
          }
        }
      }
    }
  }

  .interior-layout__content {
    padding-left: $padding-extra;
    padding-top: $padding-extra;
    height: 100%; // For Safari

    @media (max-width: $breakpoint-tablet) {
      padding: 0;
    }
  }

  .ox-card {
    box-shadow: none;
    border-right: 0 none;

    .ox-card__with-footer {
      .pagination-container {
        padding: 0 $padding-extra $padding $padding-extra;
      }
    }
  }
}

.ox_card__search-box {
  margin-left: 1rem;

  .input-label-group {
    margin-bottom: 1.5em; // Made this specific by wrapping inside ox_card__search-box so rest of the input boxes are not affected by this margin change.
  }
  .with-icon--right__label-text {
    visibility: hidden; // Hides the label when icon is on the right - used for search boxes only
  }
}
