@import './vendor';
@import '~ox-ui-css/styles/variables';
@import '~ox-ui-css/styles/helpers';
@import '~ox-ui-css/styles/base';
@import './components/typography';
@import './components/headers';
@import './imports/helpers';
@import './imports/variables';

// Used for nav items that use a red underline on hover.
// For examples on how to use see InteriorHeader or GlobalHeader.
.navbar {
  .navbar__list {
    height: 100%;

    .navbar__list-item,
    .navbar__callout {
      display: flex;
      padding-right: $grid-gutter-width * 3;

      .navbar__link {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0;

        &::before {
          opacity: 1;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

.inner-scroll {
  background-color: $app-background-color;
}

// Define HTML5 sematic elements in order to support older browsers
// https://www.w3schools.com/html/html5_browsers.asp
header,
section,
footer,
aside,
summary,
nav,
main,
article,
figure {
  display: block;
}
