@import '~ox-ui-css/styles/components/ox-global-header';
@import '~ox-ui-css/styles/variables';

.ox-global-header {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: 49px;
  }

  .navbar {
    .intralox-logo {
      width: 82px;

        // Ie10 & Ie11 CSS here
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .intralox-badge__svg {
            height: auto; 
            top: 0;
          }
          // Responsive intralox badge for ie11
          @media (max-width: $breakpoint-tablet) {
            .intralox-badge__svg {
              height: auto;
              top: 0;
          }
        }
      }
    }
  }
}