// Place all vendor sass and imports in this files

@import './node_modules/plygrid/src/scss/ply-essentials.scss';
@import './node_modules/plygrid/src/scss/components/reset';
@import './node_modules/plygrid/src/scss/components/buttons';
@import './imports/react-tooltip.css';

/*
we are loading fonts locally as opposed to the cloud so we can
start to truly build progressive web apps (and cache these assets).
to reduce load, we are bringing in only what we need, but if you need
special cases you can bring specific fonts/weights/etc. into your
custom component.

**** - if you are bringing in custom typefaces, try and set up a service
       worker to cache the assets. if you can't do that, please use a CDN.
*/

// Fonts
$font-path: '../assets/fonts';

// Import Roboto

/* roboto-regular */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src:
    url('#{$font-path}/roboto/Roboto-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/roboto/Roboto-Regular.woff') format('woff'); /* Modern Browsers */
}

/* roboto-regular-italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: fallback;
  font-weight: 400;
  src:
    url('#{$font-path}/roboto/Roboto-RegularItalic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/roboto/Roboto-RegularItalic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-bold */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: fallback;
  font-weight: 700;
  src:
    url('#{$font-path}/roboto/Roboto-Bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/roboto/Roboto-Bold.woff') format('woff'); /* Modern Browsers */
}

// Import Roboto Condensed

/* roboto-condensed-bold */
@font-face {
  font-family: 'Roboto-Condensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 700;
  src:
    url('#{$font-path}/roboto-condensed/Roboto-Condensed-Bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/roboto-condensed/Roboto-Condensed-Bold.woff') format('woff'); /* Modern Browsers */
}

/* roboto-condensed-regular */
@font-face {
  font-family: 'Roboto-Condensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src:
    url('#{$font-path}/roboto-condensed/Roboto-Condensed-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/roboto-condensed/Roboto-Condensed-Regular.woff') format('woff'); /* Modern Browsers */
}

// Import Montserrat

/* montserrat-400normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src:
    local('Montserrat Regular '),
    local('Montserrat-Regular'),
    url('#{$font-path}/montserrat/montserrat-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/montserrat/montserrat-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-400italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: fallback;
  font-weight: 400;
  src:
    local('Montserrat Regular italic'),
    local('Montserrat-Regularitalic'),
    url('#{$font-path}/montserrat/montserrat-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/montserrat/montserrat-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-600normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: fallback;
  font-weight: 600;
  src:
    local('Montserrat SemiBold '),
    local('Montserrat-SemiBold'),
    url('#{$font-path}/montserrat/montserrat-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/montserrat/montserrat-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: fallback;
  font-weight: 600;
  src:
    local('Montserrat SemiBold italic'),
    local('Montserrat-SemiBolditalic'),
    url('#{$font-path}/montserrat/montserrat-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/montserrat/montserrat-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-700normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: fallback;
  font-weight: 700;
  src:
    local('Montserrat Bold '),
    local('Montserrat-Bold'),
    url('#{$font-path}/montserrat/montserrat-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/montserrat/montserrat-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-700italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: fallback;
  font-weight: 700;
  src:
    local('Montserrat Bold italic'),
    local('Montserrat-Bolditalic'),
    url('#{$font-path}/montserrat/montserrat-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/montserrat/montserrat-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

// Vendor Overrides Happen Below Here

.units-container {
  padding: $padding * 1.75;
  position: relative;
  height: 100%;
}

.container {
  @extend .units-container;
}

.units-container-fluid,
.container-fluid {
  @extend .units-container;

  max-width: 100%;
}

.row {
  @extend .units-row;
}
