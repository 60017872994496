@import '~ox-ui-css/styles/mixins';
@import '~ox-ui-css/styles/variables';

$top: 0;

.ox-card {
  .ox-card__title--with-icon {
    font-size: $font-size-h3-lg;
  }
}

.ox-modal {
  &.show {
    @include overlay;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    overflow: hidden;

    .ox-modal__content {
      @include box-shadow(0, 0, $padding-extra, $border-color);
      animation: slidedown 0.5s;
      position: relative;
      top: $top;

      .ox-modal__card {
        max-width: 35em;
      }

      .ox-modal__message {
        font-size: $font-size-h4-lg;
      }

      .ox-modal__btn {
        color: $ox-input-placeholder-color;

        &:hover {
          color: $color-black-light;
        }
      }

      .ox-modal__buttons {
        :first-child {
          margin-right: $margin;
        }
      }
    }
  }

  &.hidden {
    position: absolute;
    margin: auto;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;
    overflow: hidden;
    max-width: 35em;
    opacity: 0;

    transition: opacity 0.25s linear;
    z-index: 99;
  }

  &.iframe-modal {
    transform: none;
    top: unset;
    align-items: baseline;

    .ox-modal__content {
      margin-top: 3.75em;
    }
  }
}

@keyframes slidedown {
  from {
    top: -40em;
  }

  to {
    top: $top;
  }
}
