@import '~ox-ui-css/styles/variables';

.badge {
  margin-left: $padding / 2;
  margin-right: $padding;
  margin-top: $padding / 2;
  margin-bottom: $padding / 2;
}

.ox-fieldset {
  .ox-fieldset__save-button {
    margin-right: $margin;
  }
}

.ox-table {
  .ox-table__delete-announcement {
    color: $intralox-red--dark;

    &:hover {
      color: $intralox-red--darkest;
      transform: scale(1.1);
    }
  }
}
