@import '~ox-ui-css/styles/variables';

.add-approved-email-btn {
    display: flex;
    align-items: center;
    gap: .25rem;
    cursor: pointer;
    color: $ox-link-blue;
}

.approved-email-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .approved-email-list__search {
        width: 100%;
        padding: .5rem .75rem;
        border: 1px solid $ox-input-border-color;

        &:focus {
            outline: none;
            border: 1px solid $ox-input-label-color-focused;
        }
        &::placeholder {
            color: $ox-input-placeholder-color;
        }
    }
}