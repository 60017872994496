@import '~ox-ui-css/styles/components/ox-card';

.ox-card__header {
  .ox-card__title {
    // The reason this isn't a modifier is because I also want styles
    // from ox-card__title in addition to styles listed in with-icon.
    &.with-icon {
      display: flex;
      flex-direction: row;
      align-items: center;

      .ox-card__icon {
        margin-right: $margin;
      }
    }
  }
  .ox_card__download-btn {
    margin-left: 1em;
  }
}

.ox-card {
  min-height: 100%;

  .ox-card__with-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.ox-card--no-shadow {
  box-shadow: none;
}
