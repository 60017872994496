@import '~ox-ui-css/styles/variables';
@import '~ox-ui-css/styles/mixins';
@import '../imports/variables';

// Base pill styles
.pill {
  border-radius: 2em;
  align-content: center;

  .pill__content {
    align-items: center;
    text-transform: uppercase;

    .content__icon {
      padding-left: $padding;
    }
  }

  &:hover, &:focus {
    transform: scale(1.05);
  }
}

// Default pill type
.pill-default {
  border: 1px solid $ox-input-placeholder-color;
  background: white;
  color: $color-gray-darker;

  &.pill-is-selected {
    border: 1px solid $ox-input-label-color;

    .btn-with-icon {
      color: $color-gray-darker;
    }
  }

  &:hover, &:focus {
    border: 1px solid $ox-input-label-color;
  }
}

// Default pill size
.pill-size-default {
  padding: $padding-small $padding-extra $padding-small $padding-extra;
  font-size: $ox-base-font-size;
}

// Small pill size
.pill-size-small {
  padding: $padding / 2 $padding-extra $padding / 2 $padding-extra;
  font-size: $ox-font-size-small;
}
