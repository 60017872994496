@import '../imports/variables';
@import '../imports/mixins';

.cx-home {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}

.cx-home__container {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-left: $padding-extra;

  @media (max-width: $breakpoint-small-desktop) {
    flex-direction: column;
  }

  @media (max-width: $breakpoint-tablet) {
    padding-left: 0;
  }

  .cx-home__customers {
    width: 100%;
    margin-right: 1em;

    @media (max-width: $breakpoint-small-desktop) {
      margin-right: 0;
      width: auto;
    }

    &--no-announcements {
      padding-right: 0;
      height: 100%;
      width: 100%;
    }
  }

  .cx-home__announcements {
    width: 40%;

    @media (max-width: $breakpoint-small-desktop) {
      width: 100%;
    }
  }

  .ox-card {
    box-shadow: none;

    .ox-card__content {
      > .ox-card {
        border: 0 none;
        box-shadow: 0 0 0;
        border-radius: 0;
      }
    }
  }
}

.dashboard-search-container {
  padding: 0 $padding-extra $padding-extra;
  border-bottom: 1px solid $border-color;
  align-items: center;
  font-size: 1em;

  .input-label-group {
    margin-bottom: 0;
    input {
      border-radius: 0 $border-radius $border-radius 0;
    }
    select {
      border-radius: $border-radius 0 0 $border-radius;
    }
  }
}

.announcement-card {
  padding: $padding-extra;

  .announcement-card__header {
    color: $color-gray-darker;
    display: flex;
    justify-content: space-between;
    padding-bottom: $padding;
    font-size: $ox-font-size-small;

    .announcement-card__header--heavy {
      font-weight: 600;
    }
  }

  .announcement-card__body {
    color: $color-body;
    overflow-wrap: break-word;

    .announcement-card__action-text {
      @include bold-link;
      letter-spacing: 0;
    }
  }
}

.announcement-anchor {
  text-decoration: none;
  color: inherit;
 
  &:hover {
    color: inherit;
    .announcement-card__action-text {
      @include bold-link-hover;
    }
  }
  &:focus {
    .announcement-card {
      .announcement-card__body {
        .announcement-card__action-text {
          color: $ox-link-visited;
        }
      }
    }
  }
  &:visited {
    .announcement-card {
      .announcement-card__body {
        .announcement-card__action-text {
          color: $ox-link-blue;
        }
      }
    }
  }
}

