@import '~ox-ui-css/styles/components/ox-table';
@import '~ox-ui-css/styles/variables';
@import '../imports/variables';

.ox-table-wrapper__loader {
  padding-bottom: $padding-extra;
}

.ox-table {
  th {
    color: $table-header-color;

    .units-row {
      margin: 0;
    }
    &.sortable {
      cursor: pointer;
      user-select: none;

      .units-row {
        flex-wrap: nowrap;

        .oxtable__header--text {
          margin-top: auto;
        }
        .oxtable__header--sort-indicator {
          padding: 0;
  
          .btn {
            display: block;
            width: 100%;
            text-align: right;
            color: $table-header-color;
            padding: 0;
            background: none;
          }
        }
      }
    }
  }

  .ox-table__link-icon-svg {
    &--no-text:hover {
      color: $ox-link-hover;
      transform: scale(1.1);
    }
  }

  .ox-table__link {
    &.tabbable {
      outline-offset: $padding;
    }
    .feather-download-cloud {
      width: 2.5em;
    }
  }
}
