@import '~ox-ui-css/styles/variables';

.download-btn__modal-body {
  .modal-body__start-download-link {
    background-color: transparent;
    color: $ox-link-blue;

    &.loading {
      color: lightgray;
      cursor: not-allowed;
    }
  }
}
