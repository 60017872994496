@import '~ox-ui-css/styles/mixins';
@import '../imports/variables';

.order-subheader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media only screen and (min-width: $breakpoint-phone) and (max-width: $breakpoint-tablet) {
    flex-direction: column;
    text-align: left;
  }
}
.document-button {
  align-self: flex-end;
  width: 100%;
}
.document-availability {
  min-height: 100%;
  flex-direction: column;
  border: 3px solid $success-color;
  border-radius: 5px;
  padding: $padding;
  justify-content: space-between;
  text-align: left;
  @media only screen and (min-width: $breakpoint-tablet) and (max-width: 820px) {
    font-size: $ox-base-font-size * 0.9;
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    margin-top: $ox-base-spacing;
  }
  @media only screen and (max-width: $breakpoint-phone) {
    margin-bottom: $ox-base-spacing;
  }
  .document-availability__list {
    margin: 0;
    .document-availability__document-listing {
      list-style-position: inside;
    }
  }
}
.documents-right-column {
  width: 16em;
}

.show-on-phone {
  display: none;
  @media only screen and (max-width: $breakpoint-phone) {
    display: block;
  }
}

.hide-on-phone {
  @media only screen and (max-width: $breakpoint-phone) {
    display: none;
  }
}
.display-on-tablet-only {
  display: none;
  @media only screen and (max-width: $breakpoint-tablet) {
    display: block;
  }
  @media only screen and (max-width: $breakpoint-phone) {
    display: none;
  }
}

.hide-on-mobile-tablet {
  @media only screen and (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.orders__table-container {
  overflow-x: auto;
}

.ox-table__dhl-tracking {
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  line-height: 0;
}
