@import '~ox-ui-css/styles/variables';
@import '~ox-ui-css/styles/mixins';
@import '~ox-ui-css/styles/helpers';

$min-width: 375px;

body {
  overflow-x: hidden;
}

.ox-notification {
  @include box-shadow(0, 0, $padding, $color-black-light);

  border-radius: $border-radius;
  z-index: 101;
  background-color: $color-white;
  padding: $padding-extra;
  min-width: $min-width;
  max-width: 25%;
  position: absolute;
  border: 1px solid $color-black-light;
  top: 2%;

  .icon-width {
    // Prevent icons from shrinking
    max-width: 13%;
  }

  &.hidden {
    visibility: hidden;
  }

  &.show {
    visibility: visible;
    animation: slidein .5s;
    left: calc(100vw - #{$min-width} - 2%);

    @keyframes slidein {
      from {
        left: 100%;
      }

      to {
        left: calc(100vw - #{$min-width} - 2%);
      }

    }

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  .ox-notification__header {
    @include condensed-font(1.25em, $ox-text-color, 0.1);

    font-weight: 400;
  }

  .ox-notification__message {
    color: $ox-text-color;
  }

  .ox-notification__icon {
    color: $ox-link-blue;
  }

  .ox-notification__close-icon {
    color: $ox-input-placeholder-color;
    height: 0;

    &:hover {
      color: $color-black-light;
    }
  }
}

.ox-notification {
  .ox-notification__button {
    &:not(:last-child) {
      margin-right: $padding;
    }
  }
}