@import '~ox-ui-css/styles/components/ox-input-group';

.input-label-group {
  input {
    &:focus {
      color: $ox-text-color;
    }
  }
}

.ox-input-group__cell {
  min-width: 130px;
  margin-right: -1px;
  width: 20%;
}
