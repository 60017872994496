@import '~ox-ui-css/styles/variables';

// Retrieved from: https://www.w3schools.com/howto/howto_css_switch.asp

.ox-toggle-label {
  margin-bottom: 0;
}

$active-color: darkgreen;
$inactive-color: $intralox-red--darkest;

$width: 60px;
$toggleBorderSpace: 3px;
$height: 33px;
$moveX: 27px;

// Note: calculated variables are not working on ie11.
// How to calculate values:
// $height: calc(#{$width} / 2 + #{$toggleBorderSpace});
// $moveX: calc(#{$width} / 2 - #{$toggleBorderSpace});

/* The switch - the box around the slider */
.ox-toggle-switch {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
}

/* Hide default HTML checkbox */
.ox-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.ox-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $inactive-color;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.ox-toggle-slider:before {
  position: absolute;
  content: '';
  height: $moveX;
  width: $moveX;
  left: $toggleBorderSpace;
  bottom: $toggleBorderSpace;
  background-color: $color-white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .ox-toggle-slider {
  background-color: $active-color;
}

input:focus + .ox-toggle-slider {
  box-shadow: 0 0 1px $color-white;
}

input:checked + .ox-toggle-slider:before {
  -webkit-transform: translateX($moveX);
  -ms-transform: translateX($moveX);
  -ms-transform: translateX($moveX);
}

/* Rounded sliders */
.ox-toggle-slider.round {
  border-radius: $height;
}

.ox-toggle-slider.round:before {
  border-radius: 50%;
}
