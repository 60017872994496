@import '~ox-ui-css/styles/variables';
@import '../imports/mixins';

.float-label-group__textarea,
.input-label-group__textarea {
  max-height: 20em;
  min-height: 10em;
}

.email-form-btns {
  .email-form-btns__submit {
    flex-grow: 1;
    margin-right: $margin;
  }
  #email-form__cancel-btn {
    flex-grow: 1;
  }
}

// Prevent columns from being moved right as documents with longer
// titles are added to the table.
.ox-table--documents {
  td:first-child {
    width: 2em;
    padding-right: 0;

    input {
      cursor: pointer;
    }
  }
  .ox-table__share-document-button {
    @include transition;
    color: $ox-link-blue;
    &:hover,
    &:focus {
      color: $ox-link-hover;
    }
  }
}

a.ox-table__link {
  justify-content: flex-start;

  &:hover,
  &:focus {
    color: $ox-link-hover;
  }
}
