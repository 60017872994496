@import '~ox-ui-css/styles/mixins';

@mixin space-between-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@mixin bold-link {
    font-weight: bold;
    color: $ox-link-blue;
    cursor: pointer;
    letter-spacing: 0.05em;
}

@mixin bold-link-hover {
    text-decoration: underline;
    color: $ox-link-hover;
}
