@import '../imports/variables';

.email-form__language-select {
  margin-right: 0;

  .email-form__msg-preview {
    border-radius: $border-radius;
    padding: $padding;
    border: 1px solid $ox-input-border-color;
    background: $ox-input-background;
  }
}
