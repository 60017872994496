@import '~ox-ui-css/styles/components/ox-button';

.btn-with-icon {
  display: flex;

  &.icon-spinning {
    animation: rotation 2s infinite linear;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.btn {
  &.btn-secondary {
    &.btn-disabled {
      background: $color-gray-dark;
      color: rgba(0, 0, 0, 0.5);

      &:hover {
        background: $color-gray-dark;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
