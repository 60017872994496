@import '~ox-ui-css/styles/components/user-input';
@import '~ox-ui-css/styles/variables';
@import './ox-forms';

$input-height: 2.88em;

.ox_input__icon--right {
  &:hover {
    cursor: pointer;
  }
}

// Read only styles for label text
.input-label-group__label-text,
.float-label-group__label-text {
  &--readOnly {
    color: $ox-input-label-color;
  }
}
.float-label-group {
  select {
    & + label {
      // Remove original caret
      &::after {
        display: none;
        visibility: hidden;
      }
    }
  }
}

// select wrapper
.input-label-group {
  select {
    &:hover,
    &:focus {
      z-index: 3;
      background-color: transparent;
    }
    & + label {
      // Remove original caret
      &::after {
        display: none;
        visibility: hidden;
      }
    }
  }
}

// Add caret
.input-label-group:after,
.float-label-group::after {
  content: '\2304';
  box-sizing: border-box;
  font-size: 1.5em;
  position: absolute;
  top: 42%;
  right: 0.5em;
  color: $border-color;
  pointer-events: none;
}

// Add input height since inputs with dates causes height
// to vary with other inputs.
.input-label-group,
.float-label-group {
  input {
    height: $input-height;
  }
}

// Read only styles for inputs
input:read-only {
  &:hover,
  &:focus {
    background-color: $ox-input-background;
    border: 1px solid $ox-input-border-color;

    &:not([type='checkbox']) {
      cursor: no-drop;
    }
  }
}

.input-label-group {
  &:hover,
  &:focus {
    &::after {
      color: $ox-text-color;
    }
  }
}
