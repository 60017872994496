@import '~ox-ui-css/styles/components/ox-tooltip';

.ox-tooltip {
  z-index: 100;
  .ox-tooltip__content {
    &.scrollable {
      max-height: 240px;
      overflow-y: scroll;
      max-width: 240px;
    }
  }
}
