@import '~ox-ui-css/styles/variables';
@import '../imports/mixins';

// Container
.pagination-container {
  @include space-between-row;
  align-items: center;
  width: 100%;
  padding-top: $padding;

  // Rows per page
  .pagination-container__rows-per-page {
    @include space-between-row;
    align-items: center;

    // Text
    .rows-per-page__text {
      padding-right: $padding;
    }

    // Select
    .input-label-group {
      padding: 0;
      margin: 0;

      select {
        background: $color-white;
        border: none;
        width: 180%;
        font-size: large;
        font-weight: bold;
        color: $ox-link-blue;

        &:focus {
          outline-offset: $padding / 4;
        }
      }

      &:after {
        top: 11%;
        right: -40%;
      }
    }
  }

  // Page of
  .pagination-container__page-of {
    display: flex;
    align-items: center;

    .page-of__btn {
      display: flex;
      color: $ox-link-blue;
      cursor: pointer;

      &:focus,
      &:hover {
        color: $ox-link-hover;
      }
    }

    .page-of__text {
      padding: 0 $padding 0 $padding;
    }
  }
}
