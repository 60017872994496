@import '~ox-ui-css/styles/variables';

.ox-icon {
  color: $ox-link-blue;

  .badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    position: relative;
    bottom: -4px;
    right: 6px;
    width: 15px;
    height: 15px;
    font-size: $ox-font-size-small;
    font-weight: bold;
    padding: 8px;
    background-color: $color-white;
    border-radius: 50%;
    border: 2px solid $color-black-light;
  }
}
